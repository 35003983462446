.leaflet-control-measure-on .leaflet-control-measure {
  box-shadow: 0 0 8px rgba(10,10,10,1.0);
}

.leaflet-measure-tooltip {
  font: 12px "Helvetica Neue", Arial, Helvetica, sans-serif;
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: 0 0 5px #BBB;
  margin: 0;
  padding: 2px;
  width: auto !important;
  height: auto !important;
  white-space: nowrap;
  font-weight: bold;
}

.leaflet-control-measure {
  cursor: pointer;
}
