.leaflet-bottom.leaflet-left {
  width: 100%;
}

.leaflet-left {
  left: 0;
}

.leaflet-bottom.leaflet-left {
  width: 100%;
}

.leaflet-control.leaflet-timeline-control{
  width: 96%;
  box-sizing: border-box;
  margin: 2%;
  margin-bottom: 20px;
  text-align: center;
}

.leaflet-control.leaflet-timeline-control input[type=range]{
  width: 75%;
}

.leaflet-control.leaflet-timeline-control .sldr-ctrl-container{
  float: left;
  width: 20%;
  box-sizing: border-box;
}

.leaflet-control.leaflet-timeline-control .button-container button{
  position: relative;
  /* width: 20%; */
  width: 37px;
  height: 25px;
  border: 1px solid grey;
  margin: 1px;
}

.leaflet-control.leaflet-timeline-control .button-container button::before, .leaflet-control.leaflet-timeline-control .button-container button::after{
  content: '';
  position: absolute;
}

.leaflet-control.leaflet-timeline-control .button-container button.play::before{
  border: 7px solid transparent;
  border-width: 7px 0 7px 10px;
  border-left-color: black;
  margin-top: -7px;
  background: transparent;
  margin-left: -5px;
}

.leaflet-control.leaflet-timeline-control .button-container button.pause{
  display: none;
}

.leaflet-control.leaflet-timeline-control .button-container button.pause::before{
  width: 4px;
  height: 14px;
  border: 4px solid black;
  border-width: 0 4px;
  margin-top: -7px;
  margin-left: -6px;
  background: transparent;
}

.leaflet-control.leaflet-timeline-control .button-container button.prev::before, .leaflet-control.leaflet-timeline-control .button-container button.prev::after{
  margin: -8px 0 0;
  background: black;
}

.leaflet-control.leaflet-timeline-control .button-container button.prev::before{
  width: 2px;
  height: 14px;
  margin-top: -7px;
  margin-left: -7px;
}

.leaflet-control.leaflet-timeline-control .button-container button.prev::after{
  border: 7px solid transparent;
  border-width: 7px 10px 7px 0;
  border-right-color: black;
  margin-top: -7px;
  margin-left: -5px;
  background: transparent;
}

.leaflet-control.leaflet-timeline-control .button-container button.next::before, .leaflet-control.leaflet-timeline-control .button-container button.next::after{
  margin: -8px 0 0;
  background: black;
}

.leaflet-control.leaflet-timeline-control .button-container button.next::before{
  width: 2px;
  height: 14px;
  margin-top: -7px;
  margin-left: 5px;
}

.leaflet-control.leaflet-timeline-control .button-container button.next::after{
  border: 7px solid transparent;
  border-width: 7px 0 7px 10px;
  border-left-color: black;
  margin-top: -7px;
  margin-left: -5px;
  background: transparent;
}

.leaflet-control.leaflet-timeline-control.playing button.pause{
  display: inline-block;
}

.leaflet-control.leaflet-timeline-control.playing button.play{
  display: none;
}

.leaflet-control.leaflet-timeline-control .button-container button.speed{
  vertical-align: top;
  font-weight: bold;
}

@media screen and (max-width: 670px) {
  .leaflet-control.leaflet-timeline-control input[type=range]{
    width: 75%;
  }

  .leaflet-control.leaflet-timeline-control .sldr-ctrl-container{
    width: 96%;
  }
}
